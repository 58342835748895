import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import {BLOCKS, INLINES, MARKS} from '@contentful/rich-text-types'
import {Box, Label, Stack, Text, InlineLink} from '@primer/react-brand'

import type {RichText} from '@github-ui/swp-core/schemas/contentful/richText'

import {getAnalyticsEvent, documentToPlainTextString} from '@github-ui/swp-core/lib/utils/analytics'

import type {CopilotFeature} from '../../../../brand/lib/types/contentful/contentTypes/copilot-feature'

import CompareTableCell from './CompareTableCell'

type Props = {
  contentfulContent: CopilotFeature
  showFree: boolean
  showPro: boolean
  showProPlus: boolean
  showBusiness: boolean
  showEnterprise: boolean
}

export default function CompareTableRow(props: Props) {
  const {contentfulContent, showFree, showPro, showProPlus, showBusiness, showEnterprise} = props
  const {sys, fields} = contentfulContent
  const {
    label,
    heading,
    subheading,
    statusForFree,
    statusForPro,
    statusForProPlus,
    statusForBusiness,
    statusForEnterprise,
    customTextForFree,
    customTextForPro,
    customTextForProPlus,
    customTextForBusiness,
    customTextForEnterprise,
  } = fields

  const cells = [
    {name: 'Free', show: showFree, status: statusForFree, text: customTextForFree},
    {name: 'Pro', show: showPro, status: statusForPro, text: customTextForPro},
    {name: 'Pro+', show: showProPlus, status: statusForProPlus, text: customTextForProPlus},
    {name: 'Business', show: showBusiness, status: statusForBusiness, text: customTextForBusiness},
    {name: 'Enterprise', show: showEnterprise, status: statusForEnterprise, text: customTextForEnterprise},
  ]

  function renderRichText(richText: RichText, fontSize: '100' | '200' = '200') {
    return documentToReactComponents(richText, {
      renderMark: {
        [MARKS.SUPERSCRIPT]: text => <sup id={`footnote-ref-${text}`}>{text}</sup>,
      },
      renderNode: {
        [BLOCKS.PARAGRAPH]: (_, children) => (
          <Text as="p" size={fontSize} weight="normal" className="lp-Pricing-table-rowheader">
            {children}
          </Text>
        ),
        [INLINES.HYPERLINK]: (node, children) => (
          <InlineLink
            href={node.data.uri}
            {...getAnalyticsEvent({
              action: documentToPlainTextString(node, ' '),
              tag: 'hyperlink',
              context: documentToPlainTextString(heading),
              location: 'comparison_table',
            })}
          >
            {children}
          </InlineLink>
        ),
      },
    })
  }

  return (
    <Stack
      key={sys.id}
      direction={{narrow: 'vertical', regular: 'horizontal'}}
      gap={{narrow: 16, regular: 32}}
      padding="none"
      className="border-bottom py-4 py-md-3"
      role="row"
    >
      <Box role="rowheader" className="flex-1 col-12 col-md-3">
        {label && (
          <div className="lp-Pricing-table-label-wrap">
            <div className="lp-ConicGradientBorder lp-ConicGradientBorder-label lp-ConicGradientBorder-label-mini d-inline-block">
              <Label size="medium" color="purple-red" className="lp-ConicGradientBorder-label-inner">
                {label.fields.text}
              </Label>
            </div>
          </div>
        )}

        {renderRichText(heading)}

        {subheading ? <div className="mt-3">{renderRichText(subheading, '100')}</div> : null}
      </Box>

      <Stack
        direction={{narrow: 'vertical', regular: 'horizontal'}}
        gap={{narrow: 16, regular: 32}}
        padding="none"
        className="col-12 col-md-8 flex-items-center"
      >
        {cells.map(cell => {
          if (!cell.show) return null

          return <CompareTableCell key={cell.name} name={cell.name} status={cell.status} text={cell.text} />
        })}
      </Stack>
    </Stack>
  )
}

try{ CompareTableRow.displayName ||= 'CompareTableRow' } catch {}