const COPY = {
  title: 'Become a risk reduction warrior',
  description: 'Stay ahead of threats with built-in security, secret protection, and dependency monitoring.',
  bento2: {
    title: 'Strengthen your development with AI',
    description: 'Write secure code at scale with AI-driven insights and automated fixes from GitHub Copilot Autofix.',
  },
  bento3: {
    title: 'Empower your team with native AppSec',
    description: 'Find and fix vulnerabilities in real time by integrating application security right into GitHub.',
  },
  testimonial: {
    quote:
      'GitHub Advanced Security has solved the risk of leaked credentials. Now, developers are alerted to the problem before they push the code live. They have a direct feedback loop.',
    name: 'Florian Koch',
    position: 'Lead developer at Deutsche Vermögensberatung',
  },
} as const

export default COPY
