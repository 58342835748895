import {createContext} from 'react'

export const PlanTypes = {
  Individual: 'individual',
  Business: 'business',
} as const

export type PlanType = (typeof PlanTypes)[keyof typeof PlanTypes]

interface PlanTypeContextValue {
  planType: PlanType
  setPlanType: (type: PlanType) => void
}

export const PlanTypeContext = createContext<PlanTypeContextValue>({
  planType: PlanTypes.Individual,
  setPlanType: () => {},
})

try{ PlanTypeContext.displayName ||= 'PlanTypeContext' } catch {}