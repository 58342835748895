export const COPY = {
  hero: {
    heading: 'Build and ship software on a single, collaborative platform',
    description: 'Join the world’s most widely adopted AI-powered developer platform.',
  },
  carousel: {
    items: [
      {
        label: 'Code',
        value: 'code',
        description: 'Build code quickly and more securely with GitHub Copilot embedded throughout your workflows.',
      },
      {
        label: 'Plan',
        value: 'plan',
        description: 'Track and coordinate your work with GitHub Issues, GitHub Projects, and insights.',
      },
      {
        label: 'Collaborate',
        value: 'collaborate',
        description:
          'Work easily with your team in real time with GitHub Pull Requests, code reviews, and GitHub Discussions.',
      },
      {
        label: 'Automate',
        value: 'automate',
        description:
          'Streamline your workflows with automated CI/CD, testing, planning, project management, issue labeling, approvals, onboarding, and more.',
      },
      {
        label: 'Secure',
        value: 'secure',
        description: 'Leverage tooling to detect, review, and fix vulnerabilities before they reach production.',
      },
    ],
    aria: {
      label: 'GitHub features',
      playButton: {
        play: 'Play demo',
        pause: 'Pause demo',
      },
      descriptionId: 'heroCarouselDescription',
    },
  },
}
