export const COPY = [
  {
    footnote:
      // eslint-disable-next-line github/unescaped-html-literal
      '<a href="https://github.blog/news-insights/research/survey-ai-wave-grows/">Survey: The AI wave continues to grow on software development teams, 2024.</a>',
  },
  {
    footnote:
      'This 7X times factor is based on data from the industry’s longest running analysis of fix rates Veracode State of Software Security 2023, which cites the average time to fix 50% of flaws as 198 days vs. GitHub’s fix rates of 72% of flaws with in 28 days which is at a minimum of 7X faster when compared.',
  },
]
