import {PlanTypes, type PlanType} from '../../context/PlanTypeContext'

import styles from './PlanTypeSegmentedControl.module.css'

interface PlanTypeSegmentedControlProps {
  value: PlanType
  onChange: (value: PlanType) => void
}

export default function PlanTypeSegmentedControl({value, onChange}: PlanTypeSegmentedControlProps) {
  return (
    <>
      <div className={styles.container} role="tablist" aria-labelledby="pricing-plans">
        <button
          className={`${styles.segment} ${value === PlanTypes.Individual ? styles.active : ''}`}
          id="individual-plan-tab"
          onClick={() => onChange(PlanTypes.Individual)}
          role="tab"
          aria-selected={value === PlanTypes.Individual}
          aria-controls="individual-plan-tab-panel"
        >
          For individuals
        </button>

        <button
          className={`${styles.segment} ${value === PlanTypes.Business ? styles.active : ''}`}
          id="business-plan-tab"
          onClick={() => onChange(PlanTypes.Business)}
          role="tab"
          aria-selected={value === PlanTypes.Business}
          aria-controls="business-plan-tab-panel"
        >
          For businesses
        </button>
      </div>
    </>
  )
}

try{ PlanTypeSegmentedControl.displayName ||= 'PlanTypeSegmentedControl' } catch {}